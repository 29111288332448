import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MdxLayout.tsx";
import SEO from 'src/components/SEO';
import MdxCard from 'src/components/MdxCard';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Theme System" mdxType="SEO" />
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "eva-design-system-theme"
      }}>{`Eva Design System Theme`}</h2>
      <p>{`In Eva Design System a `}<strong parentName="p">{`theme`}</strong>{` is a set of semantic variables and connections between them, that represents the application's look & feel to achieves the following goals:`}</p>
      <ul>
        <li parentName="ul">{`create new visual themes easily;`}</li>
        <li parentName="ul">{`flexibly change look & feel of the application by managing variables, without changing components' styles;`}</li>
        <li parentName="ul">{`switch between visual themes in app runtime without page reload;`}</li>
        <li parentName="ul">{`support of CSS properties.`}</li>
      </ul>
      <h2 {...{
        "id": "a-theme"
      }}>{`A Theme`}</h2>
      <p>{`Each theme is represented as an javaScript map with a list of key-value pairs:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`default`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  colorPrimary100`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'#f2f6ff'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimary200`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'#d9e4ff'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimary300`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'#a6c1ff'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimary400`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'#598bff'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`//...`}</span>{`
  colorPrimary900`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'#091c7a'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`

  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`/* Basic colors - for backgrounds and borders and texts */`}</span>{`
  colorBasic100`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'#ffffff'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorBasic200`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'#f7f9fc'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorBasic300`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'#edf1f7'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorBasic400`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'#e4e9f2'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorBasic500`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'#c5cee0'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`//...`}</span>{`
  colorBasic1100`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'#101426'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`

  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`/* Status colors states - focus, hover, default, active, disabled  */`}</span>{`

  colorBasicFocus`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic400'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorBasicHover`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic200'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorBasicDefault`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic300'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorBasicActive`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic400'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <p>{`Where `}<em parentName="p">{`key`}</em>{` - is a variable name, and `}<em parentName="p">{`value`}</em>{` - is a raw css value (color, string, etc) or `}<strong parentName="p">{`parent variable name`}</strong>{`, so that you can inherit values from different variables:`}</p>
      <div {...{
        "className": "gatsby-highlight has-highlighted-lines",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`default`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  colorBasicFocus`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'colorBasic400'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span></span>{`  colorBasicHover`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic200'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span></code></pre></div>
      <p>{`Here `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`colorBasicFocus`}</code>{` inherits its value from `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`colorBasic400`}</code>{`.`}</p>
      <p>{`Each theme is divided into the following semantic groups:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Colors`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Backgrounds & Borders`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Text Colors`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Fonts & Text Styles`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`General Theme Variables`}</p>
        </li>
      </ul>
      <h2 {...{
        "id": "colors"
      }}>{`Colors`}</h2>
      <p>{`All available color within the theme. 5 semantic colors (`}<code parentName="p" {...{
          "className": "language-text"
        }}>{`primary`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`success`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`info`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`warning`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`danger`}</code>{`), 6 transparency levels for every default semantic color (8%, 16%, 24%, 32%, 40%, 48%) and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`basic`}</code>{` color (backgrounds and texts). Each color has a pallet of 9 shades, except for `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`basic`}</code>{`, which has 11 shades. These colors mostly used by `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`status`}</code>{` variants of the components.`}</p>
      <p>{`Primary color shades:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` theme `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  colorPrimary100`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'#f2f6ff'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimary200`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'#d9e4ff'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimary300`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'#a6c1ff'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimary400`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'#598bff'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimary500`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'#3366ff'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimary600`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'#274bdb'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimary700`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'#1a34b8'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimary800`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'#102694'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimary900`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'#091c7a'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`

  colorPrimaryTransparent100`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'rgba(51, 102, 255, 0.08)'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryTransparent200`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'rgba(51, 102, 255, 0.16)'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryTransparent300`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'rgba(51, 102, 255, 0.24)'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryTransparent400`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'rgba(51, 102, 255, 0.32)'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryTransparent500`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'rgba(51, 102, 255, 0.4)'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryTransparent600`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'rgba(51, 102, 255, 0.48)'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <p>{`You can also tune colors used for element states:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` theme `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  colorPrimaryFocus`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorPrimary600'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryHover`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorPrimary400'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryDefault`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorPrimary500'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryActive`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorPrimary600'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryDisabled`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasicTransparent300'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryFocusBorder`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorPrimary700'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryHoverBorder`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorPrimaryHover'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryDefaultBorder`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorPrimaryDefault'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryActiveBorder`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorPrimaryActive'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryDisabledBorder`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorPrimaryDisabled'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`

  colorPrimaryTransparentFocus`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorPrimaryTransparent300'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryTransparentHover`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorPrimaryTransparent200'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryTransparentDefault`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorPrimaryTransparent100'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryTransparentActive`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorPrimaryTransparent300'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryTransparentDisabled`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasicTransparent200'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryTransparentFocusBorder`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorPrimary500'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryTransparentHoverBorder`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorPrimary500'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryTransparentDefaultBorder`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorPrimary500'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryTransparentActiveBorder`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorPrimary500'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  colorPrimaryTransparentDisabledBorder`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasicTransparent300'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <p>{`You can adjust these settings for each of the colors, to make states use lighter or darker colors.`}</p>
      <h2 {...{
        "id": "backgrounds--borders"
      }}>{`Backgrounds & Borders`}</h2>
      <p>{`A theme has 3 backgrounds (`}<code parentName="p" {...{
          "className": "language-text"
        }}>{`basic`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`alternative`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`primary`}</code>{`) each of 4 shades and also 3 borders, each of 5 shades (background shades count + 1). `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`basic`}</code>{` and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`alternative`}</code>{` backgrounds and borders utilize `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`basic`}</code>{` color shades as a source. `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`primary`}</code>{` backgrounds and borders use `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`primary`}</code>{` color. Basic backgrounds and borders heavily used by components (cards, accordions, menu, etc), when alternative (tooltips) and primary only for particular use cases, to distinguish some of the components.`}</p>
      <p>{`Basic backgrounds and borders:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` theme `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  backgroundBasicColor1`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic100'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  backgroundBasicColor2`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic200'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  backgroundBasicColor3`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic300'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  backgroundBasicColor4`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic400'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`

  borderBasicColor1`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic100'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  borderBasicColor2`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic200'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  borderBasicColor3`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic300'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  borderBasicColor4`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic400'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  borderBasicColor5`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic500'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <p>{`the most used of these are:`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`backgroundBasicColor1`}</code>{` - the lightest one, usually used for top sitting elements - cards, headers, etc.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`backgroundBasicColor2`}</code>{` - for background of the layout and input controls (inputs, checkboxes, etc)`}</li>
      </ul>
      <p>{`And vice-versa for dark themes:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` dark `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  backgroundBasicColor1`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic800'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// <- notice how we start`}</span>{`
  backgroundBasicColor2`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic900'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// with the end part`}</span>{`
  backgroundBasicColor3`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic1000'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// of the basic shades`}</span>{`
  backgroundBasicColor4`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic1100'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`

  borderBasicColor1`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic800'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  borderBasicColor2`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic900'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  borderBasicColor3`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic1000'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  borderBasicColor4`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic1100'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  borderBasicColor5`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic1100'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h2 {...{
        "id": "text-colors"
      }}>{`Text Colors`}</h2>
      <p>{`There are 5 colors within the theme: `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`basic`}</code>{` - main text color, used on top of `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`basic`}</code>{` backgrounds `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`alternate`}</code>{` - alternative color used on top of `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`alternate`}</code>{` backgrounds, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`control`}</code>{` - used on top of `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`status`}</code>{` colors (`}<code parentName="p" {...{
          "className": "language-text"
        }}>{`primary`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`success`}</code>{`, etc), `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`disabled`}</code>{` color - to indicate text/component disabled state and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`hint`}</code>{` - for secondary texts (for example placeholders and captions).`}</p>
      <p>{`Text colors use `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`basic`}</code>{` shades as a source:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` theme `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  textBasicColor`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic800'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  textAlternateColor`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic100'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  textControlColor`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic100'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  textDisabledColor`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasicTransparent600'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  textHintColor`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'colorBasic600'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h2 {...{
        "id": "fonts--text-styles"
      }}>{`Fonts & Text Styles`}</h2>
      <p>{`Each theme has two available fonts: `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`default`}</code>{` and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`secondary`}</code>{`. `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`secondary`}</code>{` font used for headers, while the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`default`}</code>{` for the rest of the elements.`}</p>
      <p>{`There are 14 text styles:`}</p>
      <ul>
        <li parentName="ul">{`6 `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`heading`}</code>{` styles, used by h1-h6 elements`}</li>
        <li parentName="ul">{`2 `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`subtitle`}</code>{` styles, used as a text of most of controls (inputs, menus, etc)`}</li>
        <li parentName="ul">{`2 `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`paragraph`}</code>{` styles, regular text`}</li>
        <li parentName="ul">{`2 `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`caption`}</code>{` styles, used by smaller texts, like tooltip or input caption`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`label`}</code>{` style, used by label element`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`button`}</code>{` text style, used by button element`}</li>
      </ul>
      <p>{`Each styles describes text `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`font-family`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`font-size`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`font-width`}</code>{` and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`line-height`}</code>{`, for instance, caption text style:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` theme `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  textCaptionFontFamily`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'fontFamilyPrimary'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  textCaptionFontSize`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'0.75rem'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  textCaptionFontWeight`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`400`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  textCaptionLineHeight`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'1rem'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <p>{`Adjust these styles to change text style of specific groups of elements.`}</p>
      <h3 {...{
        "id": "general-theme-variables"
      }}>{`General Theme Variables`}</h3>
      <p>{`This section contains other supporting theme variables, such as `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`borderRadius`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`outlineWidth`}</code>{` & `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`outlineColor`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`shadow`}</code>{`, etc.`}</p>
      <h2 {...{
        "id": "related-articles"
      }}>{`Related Articles`}</h2>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/themes/enable-theme-system"
          }}>{`Enable Theme System`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/themes/default"
          }}>{`Default Theme variables table`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/themes/cosmic"
          }}>{`Cosmic Theme variables table`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/themes/corporate"
          }}>{`Corporate Theme variables table`}</a></li>
      </ul>
    </MdxCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      